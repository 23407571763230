(function ($)
{
    $.fn.tilter = function (options) {

        var defaults = {
            referer: null,
            rotationRatio: 8,
            autoWrap: false,
            perspective: '30vw',
            perspectiveDisplay: 'inline-block'
        };

        var settings = $.extend({}, defaults, options);

        this.each(function (i, el)
        {
            var $tilter = $(el);
            
            if ( settings.autoWrap )
            {
                var $wrap = $("<div></div>");
                $wrap.css('perspective', settings.perspective);
                $wrap.css('display', settings.perspectiveDisplay);
                $tilter.wrap($wrap);
            }    
            
            
            if ( settings.referer === null && !settings.autoWrap )
            {
                settings.referer = 'body';
            }    
            

            var relX, relY,
                $referer = settings.referer ? $(settings.referer) : $tilter.parent();

            
            // So, referer can ben around tilter and still detect mouse movements.
            // https://stackoverflow.com/questions/1009753/pass-mouse-events-through-absolutely-positioned-element
            $tilter.css('pointer-events', 'none');


            $referer.on('mousemove', function (e)
            {
                // Move here because script may be run before image are loaded,
                // leading to w & h being null.
                var refWidth  = $referer.outerWidth(),
                    refHeight = $referer.outerHeight();

                relX = (e.pageX - $(e.currentTarget).offset().left - refWidth / 2) / refWidth * 180;
                relY = (e.pageY - $(e.currentTarget).offset().top - refHeight / 2) / refHeight * -180;

                $tilter.css('transform', 'rotateX(' + relY / settings.rotationRatio + 'deg) rotateY(' + relX / settings.rotationRatio + 'deg)');
            });


            $referer.on('mouseleave', function ()
            {
                // TODO: go back smoothly to normal position.
                $tilter.css('transform', '');
            });


            // Could be more element-specific with something like
            // http://marcj.github.io/css-element-queries/
            // https://stackoverflow.com/questions/6492683/how-to-detect-divs-dimension-changed
            $(window).on('resize', function ()
            {
                refWidth = $referer.outerWidth();
                refHeight = $referer.outerHeight();
            });
        });

        return this;
    };
}(jQuery));