$ = jQuery.noConflict();

$(function()
{
    var relX, relY,
        $senjutsu  = $('.senjutsu'),
        $referer   = $(window),
        refWidth   = $referer.outerWidth(),
        refHeight  = $referer.outerHeight();
    
    $referer.on('mousemove', function(e)
    {
        relX = (e.pageX - refWidth/2) / refWidth * 100;
        relY = (e.pageY - refHeight/2) / refHeight * -100;
        
        $senjutsu.css('transform', 'rotateX('+relY/10+'deg) rotateY('+relX/10+'deg)');
    });
    
    $referer.on('mouseleave', function()
    {
        // TODO: go back gently to normal position.
        $senjutsu.css('transform', '');
    });
    
    $referer.on('resize', function()
    {
        refWidth  = $referer.outerWidth();
        refHeight = $referer.outerHeight();
    });
    
});

$(function()
{
    $('.unveil').addClass('unveiled');

    $('.article__thumb img').tilter({
        autoWrap: true,
        perspective: '70vw'
    });
});